export default defineI18nConfig(() => ({
  legacy: false,
  fallbackLocale: 'en',
  numberFormats: {
    de: {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
      percent: {
        style: 'percent',
        useGrouping: false,
      },
    },
    en: {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
      percent: {
        style: 'percent',
        useGrouping: false,
      },
    },
  },
  datetimeFormats: {
    de: {
      short: {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      },
      medium: {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    en: {
      short: {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      },
      medium: {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  },
}));
